import { ISendingTestRequest, IStory, IStoryRequest } from '@/utils/types'
import { NormApi } from './norm'

export class StoryApi extends NormApi<IStory> {
  list = async (params: IStoryRequest) => {
    const response = await this.axios.get('/v1/story/list', {
      params,
    })
    return this.responseHandler(response)
  }

  single = async (id: string) => {
    const response = await this.axios.get(`/v1/story/${id}`)
    return this.responseHandler(response)
  }

  add = async (payload: IStory) => {
    const response = await this.axios.post('/v1/story/add', payload)
    return this.responseHandler(response)
  }

  update = async (payload: IStory) => {
    const { _id, ...updateData } = payload
    const response = await this.axios.put(`/v1/story/${_id}`, updateData)
    return this.responseHandler(response)
  }

  active = async (payload: IStory) => {
    const response = await this.axios.post(`/v1/story/${payload._id}/active`, payload)
    return this.responseHandler(response)
  }

  testMode = async (payload: IStory) => {
    const response = await this.axios.post(`/v1/story/${payload._id}/mode/test`, payload)
    return this.responseHandler(response)
  }

  delete = async (id: string) => {
    const response = await this.axios.delete(`/v1/story/${id}`)
    return this.responseHandler(response)
  }

  sendingTest = async (payload: ISendingTestRequest) => {
    const response = await this.axios.post(`/v1/story/sending-test/${payload.story_id}`, payload)
    return this.responseHandler(response)
  }
}

const storyApi = new StoryApi()
export default storyApi
