import { IApp, IAudience, IAudienceAnswerRequest } from '@/utils/types'
import { NormApi } from './norm'

export class UserAnswerApi extends NormApi<IApp> {
  // list = async (payload: IAudienceAnswerRequest) => {
  //   const response = await this.axios.post('/v1/user-answers', payload)
  //   return this.responseHandler(response)
  // }

  userAnswerRows = async (payload: IAudience) => {
    const response = await this.axios.post('/v1/user-answer-rows', payload)
    return this.responseHandler(response)
  }

  total_user_answers_report_scenario = async (payload: IAudienceAnswerRequest) => {
    const response = await this.axios.post('/v1/user-answers/total-user-report-scenario', payload)
    return this.responseHandler(response)
  }
}

const app = new UserAnswerApi()
export default app
